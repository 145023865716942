import React, { useCallback, useEffect, useState } from "react";
import Skill from "./Skill";
import { nanoid } from "nanoid";

const Skills = () => {
  const [skills, setSkills] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(undefined);
  useEffect(() => {
    fetch("skills.json")
      .then((res) => res.json())
      .then((data) => {
        let skillsArr = data.map((skill) => ({ ...skill, id: nanoid() }));
        setSkills(skillsArr);
      });
  }, []);
  const getCategories = () => {
    const categories = [];
    skills.forEach((skill) => {
      if (!categories.includes(skill.category)) {
        categories.push(skill.category);
      }
    });
    return categories;
  };
  const getSkills = () => {
    let skillsArr = skills || [];
    if (selectedCategory) {
      skillsArr = skills.filter((skill) => skill.category === selectedCategory);
    }
    return skillsArr;
  };
  return (
    <div id="skills" className="container px-6 mx-auto lg:px-24">
      <h2 className="text-5xl lg:mt-0 mt-10 text-white text-center mb-4 border-b-secondary font-raleway font-bold">
        Ski<span className="text-primary">lls</span>
      </h2>
      <div className="flex items-center mb-8 justify-start flex-wrap gap-x-5 gap-y-3">
        <div
          onClick={() => setSelectedCategory(undefined)}
          className={`px-5 rounded-lg py-2 cursor-pointer hover:bg-white hover:text-primary duration-300 text-sm font-medium ${
            selectedCategory === undefined
              ? "bg-white text-primary"
              : "bg-primary text-white"
          }`}
        >
          All
        </div>
        {getCategories().map((category) => (
          <div
            key={category}
            onClick={() => setSelectedCategory(category)}
            className={`px-5 rounded-lg py-2 cursor-pointer hover:bg-white hover:text-primary duration-300 text-sm font-medium ${
              selectedCategory === category
                ? "bg-white text-primary"
                : "bg-primary text-white"
            }`}
          >
            {category}
          </div>
        ))}
      </div>
      <div className="grid lg:grid-cols-6 grid-cols-2 gap-4">
        {getSkills().map((skill) => (
          <Skill key={skill.id} skill={skill} />
        ))}
      </div>
    </div>
  );
};

export default Skills;
