import React from 'react';

const Skill = ({ skill }) => {
    const { img, name } = skill;
    const nameFirst = name.split(" ")[0];
    const nameLast = name.split(" ")[1];
    return (
        <div className='bg-white p-10 rounded-lg z-40 cursor-pointer flex items-center justify-center'>
            <div>
                <img style={{ height: "150px", objectFit: "contain" }} className='w-full h-auto' src={img} alt={name} />
                <p className='text-black text-center font-bold mt-3 text-lg'>
                    {nameFirst} <span className='text-primary'>{nameLast}</span>
                </p>
            </div>
        </div>
    );
};

export default Skill;