import React, { useEffect } from 'react';

const Blogs = () => {
    useEffect(() => {
        const script = document.createElement('script');

        script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5154455000971494";
        script.async = true;

        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        }
    }, []);
    return (
        <div className='flex min-h-screen items-center justify-center'>
            <h1 className='text-6xl text-white font-raleway font-bold'>Coming <span className='text-primary'>Soon</span></h1>
        </div>
    );
};

export default Blogs;